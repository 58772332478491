import {
  Box,
  Button,
  Stack,
  TextField as InputText,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { FormProvider, Controller, useForm } from "react-hook-form";
import { InputField } from "../InputField";
import { useEffect } from "react";

const TextField = styled(InputText)(({ theme }) => ({
  marginTop: "8px",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 7,
    marginTop: 0,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },
  },
  /* "& label.Mui-focused": {
    color: theme.palette.primary.main,
  }, */
  "& label.Mui-error": {
    // color: "#A8000B !important",
    color: "#000 !important",
    // #A8000B
  },
  "& .MuiSelect-select": {
    /*  paddingTop: '15px',
     paddingBottom: '1px', */
    margin: "0 auto",
    marginTop: "3px",
    marginBottom: "2px",
    // height: '30px !important',
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B !important', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent !important", // Fondo del texto transparente
  },
}));

export const FormDynamicTemp = ({ questions, handleSave }) => {
  const formMethods = useForm();
  const theme = useTheme();
  const { control, handleSubmit, register, reset, setValue, formState, clearErrors, getValues, setError } = useForm({
    mode: "onBlur",
  });

  const handleSend = (objToSend) => {
    reset();
    // console.log('objToSend:::',objToSend)
    handleSave(objToSend);
  };

  useEffect(() => {
    if(questions){
      questions.map((qn)=>{
        if(qn.MetadataType?.toUpperCase() === "FECHA"){
          qn?.Value && setValue(qn.Name, new Date(qn?.Value))
        }
      })
    }
  }, [questions])
  
console.log('VALUES TEMP:', getValues())
  return (
    <FormProvider {...formMethods}>
      <Box
        sx={{
          display: "flex !important",
          flexDirection: "column !important",
          alignItems: "center !important",
          position: "relative",
          width: "100% !important",
          padding: 3,
          overflowY: "auto",
          ["@media only screen and (max-width: 812px)"]: {
            height: "60vh",
          },
        }}
      >
        <Stack flexDirection={"column"} sx={{ width: "100%", px: 10 }}>
          {console.log('questions noti: ', questions)}
          {!!questions &&
            questions?.map((qt) =>
              qt.MetadataType?.toUpperCase() === 'FECHA' ?
                (<InputField
                  key={qt.Name}
                  disabled={qt?.Disabled}
                  inputType="date"
                  control={control}
                  register={register}
                  name={`${qt.Name}`}
                  label={qt.Name}
                  validations={{ required: qt?.Required ?? false }}
                  error={!!formState.errors[`${qt.Name}`]}
                  helperText={
                    formState.errors[`${qt.Name}`]?.message
                  }
                  required={qt?.Required ?? false}
                  onSelect={(e) => {
                    const d = new Date(e);
                    if (isNaN(d.getTime())) {
                      setError(
                        `${qt.Name}`,
                        {
                          type: "validate",
                          message:
                            "La fecha ingresada no es válida",
                        },
                        { shouldFocus: true }
                      );
                    } else {
                      setValue(`${qt.Name}`, e, {
                        shouldValidate: true,
                      });
                      clearErrors(`${qt.Name}`);
                    }
                  }}
                />) : (
                  <Controller
                    key={qt.Name}
                    control={control}
                    name={`${qt.Name}`}
                    render={({ field: { ref, ...restFields } }) => (
                      <Box sx={{ mt: "20px" }}>
                        <Typography
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: 14,
                            },
                            display: "inline-flex",
                            alignItems: "center",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px !important",
                            lineHeight: "140%",
                            fontFamily: '"UniversNextforHSBC-Regular",sans-serif',
                            color: "#000 !important",
                            mb: 1,
                            [theme.breakpoints.down("sm")]: {
                              fontWeight: 400,
                              fontSize: "9px !important",
                              lineHeight: "144%",
                            },
                          }}
                        >
                          {qt.Name}
                        </Typography>

                        <TextField
                          {...restFields}
                          {...register(`${qt.Name}`)}
                          /* sx={{
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important", // Color del borde cuando está enfocado
                            },
                            "& fieldset": {
                              marginTop: "5px",
                            },
                          }} */
                          sx={{
                            "input::placeholder": {
                              wordSpacing: "0.2rem",
                            },
                            "& .MuiFormLabel-asterisk": {
                              color:
                            /* error ? '#A8000B !important' :  */ "black !important",
                            },
                            "& $input": {
                              height: "25px",
                            },
                          }}
                          required={true}
                          //   error={!!formState.errors[`${itemInput.Id}`]}
                          //   helperText={formState.errors[`${itemInput.Id}`]?.message}
                          inputRef={ref}
                          fullWidth
                          defaultValue={qt.Value ?? ""}
                          variant="outlined"
                          placeholder={qt.Name}
                          InputProps={{
                            sx: { borderRadius: "0px", p: 0 },
                            inputProps: {
                              // maxLength: 5,
                              style: { fontSize: "14px" },
                              outline: "none",
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            },
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                    )}
                  />
                ))}
        </Stack>
        <Box>
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: 45,
              px: 10,
              marginTop: "0.5em",
              minWidth: 220,
              maxWidth: 350,
            }}
            onClick={handleSubmit(handleSend)}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
