//The difference between ActotMVisual and ModalVisual are the conditions to show the approbal buttons
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  Card,
  Box,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  OutlinedInput,
  useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormProvider,
  useFormContext,
  Controller,
  useForm,
} from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";
import { ArrowDropDown, FiberManualRecord } from '@mui/icons-material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from '@mui/styles'

import { useStyles } from "./ModalStyles";
import { mainStyles } from '../../theme/mainStyles'
import { CustomAlert } from "../CustomAlert";
import { addSpaceToString } from "./modalsFunctions";

import ine from "../../assets/id.svg";
import passport from "../../assets/passport.svg";
import curp from "../../assets/CURP.svg";
import comprobante from "../../assets/ComprobanteGen.svg";
import consentimiento from "../../assets/consentimiento.svg";
import contrato from "../../assets/contrato.svg";
import privacidad from "../../assets/privacidad.svg";
import ingresos from "../../assets/ingresos.svg";
import nom from "../../assets/NOM.svg";
import ubicacion from "../../assets/ubicacion.svg";
import docGenerico from "../../assets/docGenerico.svg";
import evAudio from "../../assets/evAudio.svg";
import evVideo from "../../assets/evVideo.svg";
import frames from "../../assets/frames.svg";
import procesandoVideo from "../../assets/procesando_video.gif";

import loadDoc from "../../assets/loadDoc.svg";
import { documentActions, validationActions, fileActions, catalogActions } from '../../store/actions';
import { UseImage, CustomLoadingOverlay, AlertConfirm } from "../index";
import { documentRequests } from "../../services";
import { TableRowControl } from "../table";
import { Alert } from '../'
import VisorPDF from "../Visor/VisorPDF";

const useClasses = makeStyles((theme) => ({
  modalEditable: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
    minHeight: "75vh",
  },
  inputText: {
    marginTop: 15,
    "& .MuiOutlinedInput-input": {
      height: 25,
      padding: "10px 15px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
      borderColor: "red",
      fontSize: 14,
      "& fieldset legend span": {
        display: "none",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#000",
      position: "relative",
      transform: "none",
      fontSize: 14,
      marginBottom: 8,
      marginTop: 5,
      display: "flex",
      alignItems: "center",
      pointerEvents: "auto",
      fontWeight: 500,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  outlinedText: {
    marginTop: 15,
    borderRadius: 12,
    fontSize: 14,
    "& .MuiOutlinedInput-input": {
      height: 25,
      padding: "10px 15px",
    },
  },
  accSummary: {
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  tableHead: {
    backgroundColor: "#AAA",
    "& th": {
      color: "#FFF",
    },
  },
  errorInput: {
    "& fieldset": {
      border: `2px solid red`,
    },
    "& .MuiFormLabel-root": {
      color: "red",
    },
  },
  indicator: {
    color: "red",
  },
  capitalize: {
    textTransform: "capitalize !important",
  },
}));

export function ModalVisual({
  docName,
  idDoc,
  metaData,
  docVal,
  validation,
  docsApproved,
  extDocVal,
  refresh,
  statusVideo,
  IsValidate,
  fileId,
  optionsList,
  disabledConfirmAlert,
}) {
  const theme = useTheme()
  const localStyles = useStyles(theme);
  const cardStyle = mainStyles.cards();
  const dispatch = useDispatch();
  const classes = useClasses(theme);

  const [open, setOpen] = useState(false);
  const [approved, setApproved] = useState(false);
  const [extApproved, setExtApproved] = useState(false);
  const [groups, setGroups] = useState([]);
  const [openDocFileApproval, setOpenDocFileApproval] = useState(false);
  const [openDocFileRejection, setOpenDocFileRejection] = useState(false);
  const [openExtApproval, setOpenExtApproval] = useState(false);
  const [openExtRejection, setOpenExtRejection] = useState(false);
  const [comment, setComment] = useState("");
  const [isApprovedDocument, setIsApprovedDocument] = useState(false);

  const [openEditableModal, setopenEditableModal] = useState(false);
  const [documentInfo, setdocumentInfo] = useState(null);
  const [loading, setloading] = useState(false);
  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;
  const document = useSelector((state) => state.Document);
  const docValidate = useSelector((state) => state.DocumentValidation);
  const extDocValidation = useSelector((state) => state.DocExternalVal); // to validate docs without affecting the file
  // const downloadDoc = useSelector((state) => state.DownloadDocument);

  useEffect(() => {
    if (openDocFileApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: <Box>
            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              ¿Estás seguro de aprobar el documento?
            </Typography>
          </Box>,
          okbtntext: "Aceptar",
          notbtntext: "Cancelar",
        },
        (result) => {
          if (result.isConfirmed) {
            let objRequest = {
              Document_Id: idDoc,
              Approved: true,
              Comments: "",
              ValidateFileComplete: true,
            }

            dispatch(validationActions.PostDocumentValidation(objRequest));
            setApproved(true);
            dispatch(fileActions.AsignateCurrentUser({ File_Id: fileId }));
          } else if (result.dismiss === "cancel" && result.isDismissed) {

          }
          setOpenDocFileApproval(false)
        }
      );
    }
  }, [openDocFileApproval])

  useEffect(() => {
    if (openExtApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: <Box>
            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              ¿Estás seguro de aprobar el documento?
            </Typography>
          </Box>,
          okbtntext: "Aceptar",
          notbtntext: "Cancelar",
        },
        (result) => {
          if (result.isConfirmed) {
            sendExtApproval()
          } else if (result.dismiss === "cancel" && result.isDismissed) {

          }
          setOpenExtApproval(false)
        }
      );
    }
  }, [openExtApproval])

  const handleAcceptDocument = async (formData) => {
    const generalMetadata = documentInfo.metadata.map((meta, i) => {
      return {
        ...meta,
        Value: formData[meta.Name],
      };
    });
    let body = {
      Metadata: [...generalMetadata],
    };
    const nameTables = documentInfo.metadataTables.map((el) => el.Name);
    const tablesKeys = nameTables.map((nameTab, i) => {
      let aux = [];
      Object.keys(formData).forEach((keyform) => {
        if (keyform.startsWith(nameTab)) {
          const fields = keyform.split("-");
          aux = [
            ...aux,
            {
              tableName: fields[0],
              fieldName: fields[1],
              order: +fields[2],
              fieldValue: formData[keyform],
            },
          ];
        }
      });
      return aux;
    });

    const groups = tablesKeys.map((tab) => {
      let aux = [];
      tab.forEach((t) => {
        aux[t.order] = [...(aux[t.order] ?? []), t];
      });
      return aux;
    });

    groups.forEach((row, i) => {
      const rows = row.map((r) => {
        return r.map((el) => ({ TextValue: el.fieldValue, Confidence: 1.0 }));
      });
      const table = {
        Columns: JSON.parse(documentInfo.metadataTables[i].Value).Columns,
        Rows: rows,
      };
      body = {
        ...body,
        Metadata: [
          ...body.Metadata,
          {
            ...documentInfo.metadataTables[i],
            Value: JSON.stringify(table),
          },
        ],
      };
    });

    body = {
      ...body,
      ObjectId: idDoc,
      Metadata: [...body.Metadata, ...documentInfo.metadataExtra],
      ContinueWorkFlow: false,
    };

    try {
      setloading(true);
      const response = await documentRequests.updateMetadata(body);
      const responseValidate = await documentRequests.validateDocument({
        Document_Id: idDoc,
        Approved: true,
        Comments: "",
        ValidateFileComplete: false,
      });
      setopenEditableModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const indicatorNotApprovedConfidence = () => {
    return (
      documentInfo.metadata.filter((el) => !el.ApprovedConfidence).length > 0
    );
  };

  const itIncludes = (oneString) => {
    if (oneString.includes("INE / IFE")) {
      return ine;
    } else if (
      oneString.includes("PASAPORTE") ||
      oneString.includes("pasaporte") ||
      oneString.includes("Pasaporte")
    ) {
      return passport;
    } else if (oneString.includes("curp") || oneString.includes("CURP")) {
      return curp;
    } else if (
      (oneString.includes("consentimiento") ||
        oneString.includes("Consentimiento")) &&
      (oneString.includes("INE") || oneString.includes("IFE"))
    ) {
      return consentimiento;
    } else if (
      oneString.includes("contrato") ||
      oneString.includes("Contrato")
    ) {
      return contrato;
    } else if (
      oneString.includes("privacidad") ||
      oneString.includes("Privacidad")
    ) {
      return privacidad;
    } else if (
      oneString.includes("nom") ||
      oneString.includes("NOM") ||
      oneString.includes("norma") ||
      oneString.includes("Nom") ||
      oneString.includes("Expediente") ||
      oneString.includes("expediente")
    ) {
      return nom;
    } else if (
      oneString.includes("TELMEX") ||
      oneString.includes("Telmex") ||
      oneString.includes("CFE") ||
      oneString.includes("cfe") ||
      oneString.includes("domicilio") ||
      oneString.includes("Domicilio")
    ) {
      return comprobante;
    } else if (
      oneString.includes("Ingresos") ||
      oneString.includes("INGRESOS") ||
      oneString.includes("ingresos")
    ) {
      return ingresos;
    } else if (
      oneString.includes("Ubicación") ||
      oneString.includes("ubicación")
    ) {
      return ubicacion;
    } else if (
      oneString.includes("Evidencia Video") ||
      oneString.includes("Evidencia video")
    ) {
      return evVideo;
    } else if (oneString.includes("Video frame")) {
      return frames;
    } else if (oneString.includes("Audio") || oneString.includes("audio")) {
      return evAudio;
    } else {
      return docGenerico;
    }
  };
  console.log('Document:::', document)
  const getDocumentInfo = async () => {
    try {
      const response = await documentRequests.getDocumentInfo(idDoc);
      if (response.Body.Metadata.length > 0) {
        let metadata = response.Body.Metadata.filter((el) => !el.GroupName);
        let metadataTables = metadata.filter((el) => el.ValueType);
        let metadataExtra = response.Body.Metadata.filter((el) => el.GroupName);

        let openEM =
          response.Body?.Validation?.Status === null &&
            response.Body?.Metadata?.find(
              (item) => item.Name === "Confianza aprovada" && item.Value === "NO"
            )?.Value === "NO"
            ? true
            : false;
        setopenEditableModal(openEM);
        setOpen(!openEM);
        setIsApprovedDocument(
          response.Body?.Validation?.Approved === true ? true : false
        );
        metadata = metadata.filter((el) => !el.ValueType);
        let docInfo = {
          metadata,
          metadataTables,
          metadataExtra,
        };
        setdocumentInfo(docInfo);
        return response?.Body?.Extension
      } else {
        setOpen(true);
        setIsApprovedDocument(
          response.Body?.Validation?.Approved === true ? true : false
        );
        return response.Body?.Extension ?? '.pdf'
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log('downloadDoc:::',downloadDoc)

  /* useEffect(() => {
    if(downloadDoc?.items){
      const asd = base64AString(downloadDoc?.items)
  
      console.log('ATOBBED: ',asd)
    }
  }, [downloadDoc]) */


  //MODAL
  const handleClickOpen = () => {
    getDocumentInfo().then((dat) => {
      /* if(dat === '.htm' || dat === '.html'){
        dispatch(
          documentActions.DownloadDocument(idDoc)
        );
      }else{ */
      dispatch(documentActions.GetDocument(idDoc, dat));
      /* } */
    }).catch((err) => console.log('error:', err));
  };

  const handleClose = () => {
    if (!openEditableModal) {
      setOpen(false);
      setComment("");
    } else {
      setopenEditableModal(false);
    }
  };


  useEffect(() => {
    const getGroups = metaData
      .map((data) => {
        return data.GroupName;
      })
      .reduce((a, b) => (a.includes(b) ? a : [...a, b]), [])
      .map((grp) => {
        return {
          grupo: grp,
          info: metaData.filter((grpJ) => grp === grpJ.GroupName),
        };
      });
    setGroups(getGroups);

    if (docValidate.error !== undefined && approved) {
      alert(docValidate.error);
      Alert({
        icon: 0,
        title: docValidate.error,
      });
    }
    if (docValidate.items !== undefined && approved) {
      Alert({
        icon: "success",
        title: "Documento validado.",
      });
      refresh();
      handleClose();
    }
  }, [docValidate]);


  const sendExtApproval = () => {
    setExtApproved(true);
    dispatch(
      validationActions.SendDocExternalVal({
        Document_Id: idDoc,
        Approved: true,
        Comments: "",
        ValidateFileComplete: false,
      })
    );
    handleClose();
  };

  const sendExtRejection = () => {
    setExtApproved(true);
    dispatch(
      validationActions.SendDocExternalVal({
        Document_Id: idDoc,
        Approved: false,
        Comments: comment,
        ValidateFileComplete: false,
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (extDocValidation.error !== undefined && extApproved) {
      alert(extDocValidation.error);
    }
    if (extDocValidation.items !== undefined && extApproved) {
      refresh();
      handleClose();
      console.log("se aprobo el doc");
    }
  }, [extDocValidation]);

  return (
    <div id="si">
      <FormProvider {...formMethods}>
        <Card className={cardStyle.card} onClick={handleClickOpen}>
          <div className={localStyles.iconSection}>
            {docVal === null ? (
              <UseImage
                src={loadDoc}
                type="img"
                className={cardStyle.loadDocIcon}
              />
            ) : docVal ? (
              <CheckCircleIcon style={{ color: "#00847F" }} />
            ) : docVal === false ? (
              <CancelIcon style={{ color: "red" }} />
            ) : (
              ""
            )}
          </div>
          <UseImage
            src={itIncludes(docName)}
            className={localStyles.icon}
            type="img"
          />

          <Typography className={localStyles.p}>{docName}</Typography>
        </Card>

        <Dialog
          open={openEditableModal}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              minHeight: "80%",
            },
          }}
        >
          <div className={localStyles.containerResult}>
            <div className={localStyles.leftColumnResult}>
              <Typography
                variant="h4"
                style={{ padding: "30px 20px 0px 20px" }}
              >
                {docName}
                {isApprovedDocument ? (
                  <CheckCircleIcon style={{ color: "#00847F" }} />
                ) : (
                  <FiberManualRecord
                    style={{
                      color: "#00847F",
                      fontSize: 40,
                      marginLeft: 7,
                      marginBottom: 3,
                    }}
                  />
                )}
              </Typography>
            </div>
            <div className={localStyles.rightColumnResult}>
              <CloseIcon
                style={{ color: "#898B8D" }}
                className={localStyles.closeIconNew}
                onClick={handleClose}
              />
            </div>
          </div>
          <Grid container spacing={2} className={classes.modalEditable}>
            <Grid
              item
              xs={12}
              sm={
                documentInfo?.metadata.length > 0 ||
                  documentInfo?.metadataTables.length > 0
                  ? 7
                  : 12
              }
            >
              {document.loading ? (
                <CustomLoadingOverlay
                  active={document.loading} text="Espera un momento por favor..."
                />
              ) : (
                <ShowDocViewerType doc={document} docName={docName}/>
              )}
            </Grid>

            {(documentInfo?.metadata.length > 0 ||
              documentInfo?.metadataTables.length > 0) && (
                <Grid item xs={12} sm={5}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ArrowDropDown />}
                        className={classes.accSummary}
                      >
                        <Typography
                          variant="h6"
                          className={
                            indicatorNotApprovedConfidence()
                              ? classes.indicator
                              : undefined
                          }
                        >
                          Edición de metadatos
                          {indicatorNotApprovedConfidence() && (
                            <FiberManualRecord
                              fontSize="small"
                              style={{ color: "red", marginLeft: 5 }}
                            />
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          {documentInfo.metadata.map((doc, i) => (
                            <Grid item xs={12} key={doc.Name + i}>
                              <Controller
                                control={control}
                                name={doc.Name}
                                defaultValue={doc.Value}
                                render={({ field: { ref, ...restFields } }) => (
                                  <TextField
                                    {...restFields}
                                    inputRef={ref}
                                    fullWidth
                                    variant="outlined"
                                    className={`${classes.inputText} ${!doc.ApprovedConfidence
                                      ? classes.errorInput
                                      : ""
                                      }`}
                                    label={doc.Name}
                                  />
                                )}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {documentInfo.metadataTables.map((table, i) => (
                      <Accordion key={table.Name + i} defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDown />}
                          className={classes.accSummary}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            style={
                              !table.ApprovedConfidence
                                ? { color: "red" }
                                : undefined
                            }
                            className={classes.capitalize}
                          >
                            {table.Name.toLowerCase()}
                            {!table.ApprovedConfidence && (
                              <FiberManualRecord
                                fontSize="small"
                                style={{ color: "red", marginLeft: 5 }}
                              />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableEditable data={table} />
                        </AccordionDetails>
                      </Accordion>
                    ))}

                    <Grid container style={{ marginTop: "auto" }}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            maxWidth: 300,
                            margin: "10px auto",
                            display: "block",
                            borderRadius: 16,
                            borderColor: "rgb(225, 2, 17)",
                            color: "rgb(225, 2, 17)", color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' }
                          }}
                          disabled={loading}
                        >
                          Rechazar documento
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          maxWidth: 300,
                          margin: "20px auto 0 auto",
                          display: "block",
                          borderRadius: 16,
                          backgroundColor: "rgb(225, 2, 17)",
                          color: "#FFF",
                        }}
                        onClick={handleSubmit(handleAcceptDocument)}
                        disabled={loading}
                      >
                        {loading ? (
                          <CustomLoadingOverlay
                            active={loading} text="Espera un momento por favor..."
                          />
                        ) : (
                          "Aprobar documento"
                        )}
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              )}
          </Grid>
        </Dialog>

        <Dialog
          open={open}
          onClose={()=>handleClose()}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              overflow: "hidden",
              minHeight: "80%",
            },
          }}
        >
          <Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}>

              <Typography
                variant="h5"
                style={{ padding: "5px 0.5em", width: '100%' }}
              >
                {docName}
                {isApprovedDocument ? (
                  <CheckCircleIcon style={{ color: "#00847F" }} />
                ) : (
                  <FiberManualRecord
                    style={{
                      color: "#f9e79f",
                      fontSize: 30,
                      marginLeft: 7,
                      marginBottom: 3,
                    }}
                  />
                )}
              </Typography>

              <CloseIcon
                style={{ color: "#898B8D" }}
                className={localStyles.closeIconNew}
                onClick={handleClose}
              />
            </Box>

            <Grid container spacing={2} className={classes.modalEditable}>
              <Grid item xs={12} sm={metaData.length > 0 ? 7 : 12}>
                {statusVideo !== "VideoCompleto" &&
                  statusVideo !== "Rechazado" &&
                  (document.extension === ".mp4" || document.extension === ".webm") &&
                  (document.items === undefined || document.items === null) ? (
                  <div>{console.log('SI ENTRO AL VIDEO')}
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <UseImage
                        className={localStyles.imgProcesandoVideo1}
                        src={procesandoVideo}
                        type="img"
                      />
                    </div>
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {statusVideo !== "VideoCompleto" &&
                        statusVideo !== "Rechazado" &&
                        (document.items === undefined ||
                          document.items === null) ? (
                        <Typography
                          variant="p"
                          className={localStyles.modalEstatusProcesando}
                        >
                          <b>Procesando video</b>
                        </Typography>
                      ) : (document.extension === ".mp4" || document.extension === ".webm") ? (
                        <Typography
                          variant="p"
                          className={localStyles.modalEstatusProcesandoSucces}
                        >
                          {statusVideo}
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                ) : document.loading ? (
                  <CustomLoadingOverlay
                    active={document.loading} text="Espera un momento por favor..."
                  />
                ) : (document.extension === ".mp4" || document.extension === ".webm") ? (
                  <video
                    className="videoClass"
                    width="100%"
                    height="85%"
                    controls
                    autoPlay
                    loop
                    poster="fotograma.png"
                  >
                    <source src={document.items} type={document.type} />
                    <p>Tu navegador no soporta HTML5</p>
                  </video>
                ) : (
                  <ShowDocViewerType doc={document} docName={docName}/>
                )}
              </Grid>

              {metaData.length > 0 && (
                <Grid item xs={12} sm={5}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {metaData.length > 0 ? (
                      <Typography variant="h6" sx={{ width: "100%", textAlign: "center" }}>
                        Metadatos
                      </Typography>
                    ) : null}
                    {metaData.length > 0 && (
                      <div className={localStyles.content}>
                        <div className={localStyles.scroll}>
                          {groups.map((grp) => (
                            <div className={localStyles.groupSection}>
                              <Typography
                                variant="p"
                                className={localStyles.groupTitle}
                              >
                                {grp.grupo}
                              </Typography>
                              <div className={localStyles.lineVisual}></div>
                              {grp.info.map((data) => (
                                <div
                                  className={
                                    data?.ValueType === "table"
                                      ? ""
                                      : localStyles.information
                                  }
                                >
                                  {data?.ValueType === "table" ? (
                                    <div
                                      className={localStyles.informationTables}
                                    >
                                      <Typography className={localStyles.name}>
                                        {data.Name}
                                      </Typography>
                                      <TableRowControl dataItems={data} />
                                    </div>
                                  ) : (
                                    <div style={{ height: "100%" }}>
                                      <Typography className={localStyles.name}>
                                        {data.Name}
                                      </Typography>
                                      {grp.grupo == "Reconocimiento facial" &&
                                        data.Name.includes("Face") ? (
                                        <Typography
                                          className={localStyles.dataValue}
                                        >
                                          {addSpaceToString(data.Value)}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          className={localStyles.dataValue}
                                        >
                                          {data.Value === "SI" ? (
                                            <CheckCircleIcon
                                              style={{ color: "#00847F" }}
                                            />
                                          ) : data.Value === "NO" ? (
                                            <CancelIcon
                                              style={{ color: "red" }}
                                            />
                                          ) : data.Value === "True" ? (
                                            <CheckCircleIcon
                                              style={{ color: "#00847F" }}
                                            />
                                          ) : data.Value === "False" ? (
                                            <CancelIcon
                                              style={{ color: "red" }}
                                            />
                                          ) : (
                                            data.Value
                                          )}
                                        </Typography>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>

            {

              validation.ShowSelectApproval === true && IsValidate === true &&
                docVal === null ? (
                <DialogActions style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: 45, px: 10, width: "40%", maxWidth: 380, }}
                    onClick={() => { setOpenDocFileApproval(true) }} >
                    Aprobar documento
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ height: 45, px: 10, width: "40%", maxWidth: 380, color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } }}
                    onClick={() => { setOpenDocFileRejection(true) }} >
                    Rechazar documento
                  </Button>
                </DialogActions>
              ) : (
                ""
              )
            }
            {docVal === null && extDocVal === true ? (
              <DialogActions style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ height: 45, px: 10, width: "40%", maxWidth: 380 }}
                  onClick={() => { setOpenExtApproval(true) }} >
                  Aprobar documento
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ height: 45, px: 10, width: "40%", maxWidth: 380, color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } }}
                  onClick={() => { setOpenExtRejection(true) }} >
                  Rechazar documento
                </Button>
              </DialogActions>
            ) : (
              ""
            )}

            <CustomAlert
              closeAction={() => {
                setOpenDocFileRejection(false);
                setComment("");
              }}
              open={openDocFileRejection}
              title="Rechazar Documento"
              body="Específica el motivo de rechazo del documento."
              leftButtonText="Cancelar"
              leftButtonAction={() => {
                setOpenDocFileRejection(false);
                setComment("");
              }}
              rightButtonText="Aceptar"
              list={optionsList}
              valueComment={comment}
              commentPlaceHolder="Motivo de rechazo"
              setValueComment={setComment}
              disabledConfirm={disabledConfirmAlert}
              rightButtonAction={() => {
                let objRequestRechazo = {
                  Document_Id: idDoc,
                  Approved: false,
                  Comments: comment,
                  ValidateFileComplete: true,
                }
                dispatch(validationActions.PostDocumentValidation(objRequestRechazo));
                dispatch(fileActions.AsignateCurrentUser({ File_Id: fileId }));
                setApproved(true);
              }}
            />

            <CustomAlert
              closeAction={() => {
                setOpenExtRejection(false);
                setComment("");
              }}
              open={openExtRejection}
              title="Rechazar Documento"
              body="Específica el motivo de rechazo del documento."
              leftButtonText="Cancelar"
              leftButtonAction={() => {
                setOpenExtRejection(false);
                setComment("");
              }}
              rightButtonText="Aceptar"
              comments={true}
              valueComment={comment}
              commentPlaceHolder="Motivo de rechazo"
              setValueComment={setComment}
              rightButtonAction={sendExtRejection}
            />
          </Box>
        </Dialog>
      </FormProvider>
    </div>
  );
}

const ShowDocViewerType = ({ doc, docName }) => {
  const [texto, setTexto] = useState(null);

  useEffect(() => {
    const obtenerTextoDesdeBlob = async (url) => {
      try {
        const respuesta = await fetch(url);
        if (!respuesta.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await respuesta.blob();
        const texto = await blob.text();
        return texto;
      } catch (error) {
        console.error("Error al obtener el texto desde el blob:", error);
        return null;
      }
    };

    if (['.htm', '.html'].includes(doc.extension)) {
      obtenerTextoDesdeBlob(doc.items).then(texto => setTexto(texto));
    }
  }, [doc]);

  if (doc.extension === '.pdf' || !texto) {
    return (<VisorPDF
      pdfUrl={doc.items}
      pdfName={docName ?? "Doc1"}
      height={"75vh"}
      downloadActive
      printActive
      changePage
      showCurrentPage
    />)
    /* return (
      <object
        width="100%"
        height="100%"
        style={{ maxHeight: "100vh" }}
        data={doc.items}
        type={(doc?.type?.toLowerCase() ?? doc?.extension?.toLowerCase()) === '.pdf' ? 'application/pdf' : 'text/html'}
      >
        {" "}
      </object>
    ); */
  }

  return (
    <object
      width="100%"
      height="100%"
      style={{ maxHeight: "100vh" }}
      data={URL.createObjectURL(new Blob([texto], { type: 'text/html' }))}
      type="text/html"
    >
      {" "}
    </object>
  );
};

export default ShowDocViewerType;



const TableEditable = (props) => {
  const classes = useClasses();
  const [dataTable, setdataTable] = useState([]);
  const { control } = useFormContext();

  useEffect(() => {
    if (props.data) {
      setdataTable(JSON.parse(props.data.Value));
    }
  }, [props]);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {dataTable.Columns?.map((col, i) => (
              <TableCell key={col + i}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {dataTable.Rows?.map((row, i) => (
            <TableRow key={i}>
              {row.map((cell, j) => (
                <TableCell key={cell.TextValue + i}>
                  <Controller
                    control={control}
                    name={`${props.data.Name}-${dataTable.Columns[j]}-${i}`}
                    defaultValue={cell.TextValue}
                    render={({ field: { ref, ...restFields } }) => (
                      <OutlinedInput
                        {...restFields}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        className={classes.outlinedText}
                      />
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
