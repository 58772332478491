import React, { useState, useEffect } from 'react'
import protect from '../../utils/protect'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fileActions } from '../../store/actions'
import { Typography, Tooltip, IconButton, useTheme } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import GavelIcon from '@mui/icons-material/Gavel';

import GroupIcon from '../../assets/GroupIcon';
import Apoderado from '../../assets/Apoderado';
import { FullWidthTabs } from '../../components'
import { useStyles } from './DetailStyles'
import { Logout } from '../../utils'
import { Alert } from '../../components/Alert'

function DetailsComponent() {
  const theme = useTheme()
  // const mainPage = mainStyles.mainContainer()
  const localStyles = useStyles(theme)
  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const finbeColor = theme.palette.iconPrincButton.main
  const white = '#ffffff'

  const infoFile = useSelector(state => state.FileInfo)
  const infoActors = useSelector(state => state.ActorsInfo)
  // const rolUser = useSelector((state) => state.AuthenticationReferer.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name)
  const modulesUser = useSelector((state) => state.AuthenticationReferer.items?.UserLoginData?.SecurityLoginData?.Applications[0]?.Modules)
  const functionsUser = modulesUser.find(mod=>mod.Name === "Home")?.Functions
  const rolUser = functionsUser.some(func=>func.Name === "Seguimiento")

  const [firstName, setFirstName] = useState("")
  const [initial, setInitial] = useState("")
  const [folio, setFolio] = useState("")
  const [status, setStatus] = useState("")
  const [validation, setValidation] = useState({})
  const [actors, setActors] = useState([])
  const [selectedActor, setSelectedActor] = useState({})

  const [groupIconColor, setGroupIconColor] = useState(white)
  const [apoderadoColor, setApoderadoColor] = useState(finbeColor)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    console.log('ENTRO AQUI')
    dispatch(fileActions.GetFileInfo(id))
    dispatch(fileActions.GetActorsInfo(id))
  }, [])

  useEffect(() => {
    if (infoFile.items !== undefined) {
      setFirstName(infoFile.items.FullName)
      setInitial(infoFile.items.Name?.split("")[0])
      setValidation(infoFile.itemsValidation)
      setFolio(infoFile.items.Folio)
      setStatus(infoFile.items.Stage.Description)

    } else if (infoFile.error !== undefined) {
      if (infoFile.error == 'unauthorized') {
      } else {
        alert(infoFile.error)
      }
    }
  }, [infoFile])

  useEffect(() => {
    if (infoActors.items !== undefined) {
      setActors(infoActors.items)

    }
  }, [infoActors])

  const changeActor = (actor) => {
    setSelectedActor(actor)
    setGroupIconColor(finbeColor)
  }

  const renderComponent = (actor) => {
    if (actor.TypeName.includes('Solicitante')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <AccountCircleIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Aval')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <MonetizationOnIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Accionista')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <DataUsageIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Legal')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <GavelIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Apoderado')) {
      return (<Apoderado fill={actor.Id === selectedActor.Id ? white : apoderadoColor} />);
    } else {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <AccountCircleIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    }
  };

  return (
    <>
      <div className={localStyles.headerOfSection}>
        <div className={localStyles.nameSection}>

          {actors.length > 0 && Object.keys(selectedActor).length === 0
            ? <div className={localStyles.requestIcon}>
              <GroupIcon fill={white} />
            </div>
            : actors.length > 0 && Object.keys(selectedActor).length > 0
              ? <Tooltip title='Solicitud'>
                <div className={localStyles.iconBackToRequest}
                  onClick={() => { window.location.reload() }}
                  onMouseOver={() => { setGroupIconColor(white) }}
                  onMouseOut={() => { setGroupIconColor(finbeColor) }}
                >
                  <GroupIcon fill={groupIconColor} />
                </div>
              </Tooltip>
              : <div className={localStyles.icon}>
                <p className={localStyles.capital}>{initial}</p>
              </div>
          }
          {/* {console.log("Items values: ", infoFile)} */}
          <div className={localStyles.nameAndFolio}>
            <Typography variant="p" className={localStyles.name}>{actors.length > 0 ? "Solicitud grupal" : firstName}</Typography>
            <Typography variant="p" className={localStyles.folio}>{`Folio: ${folio} `}
              {!rolUser ? (validation.FileApproval === true && infoFile.items?.Status?.includes("Finalizado") ? <span className={localStyles.accepted}>/ Solicitud aprobada</span>
                : validation.FileApproval === false && infoFile.items?.Status?.includes("Cancelado") ? <span className={localStyles.rejected}>/ Solicitud rechazada</span>
                  : <span className={localStyles.status}>{`/ ${status}`}</span>) : ''
              }</Typography>
          </div>
        </div>
        <div className={localStyles.sectionActors}>
          {actors.length > 0 ?
            actors.map((actor) =>
              <Tooltip title={`${actor.TypeName} ${actor.Name === null ? '' : actor.Name}`}>
                <button
                  className={actor.Id === selectedActor.Id ? localStyles.iconSelectedActor : localStyles.iconActor}
                  onMouseEnter={() => { actor.TypeName.includes('Apoderado') && setApoderadoColor(white) }}
                  onMouseLeave={() => { actor.TypeName.includes('Apoderado') && setApoderadoColor(finbeColor) }}
                  onClick={() => { changeActor(actor) }}>
                  {renderComponent(actor)}
                </button>
              </Tooltip>
            ) : ""}
        </div>
      </div>

      {Object.keys(selectedActor).length > 0
        ? <FullWidthTabs currentActor={selectedActor} actorId={selectedActor.Id} />
        : <FullWidthTabs currentActor={selectedActor} actorId={null} multipleActors={actors} />}
      {/* </div> */}
    </>
  )
}

export default protect(DetailsComponent);

